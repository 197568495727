import { render, staticRenderFns } from "./IpsExceededLimitCode.vue?vue&type=template&id=00e56807&scoped=true"
import script from "./IpsExceededLimitCode.vue?vue&type=script&lang=js"
export * from "./IpsExceededLimitCode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e56807",
  null
  
)

export default component.exports